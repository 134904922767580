import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import '../styles/style.css'

interface Props {
    pageTitle: string,
    children: React.ReactNode
}

const Layout: React.FunctionComponent<Props> = ({ pageTitle, children }) => {
    return (
        <div>
            <title>{pageTitle}</title>
            <div>
                <StaticImage src="../images/itIsNowSafe.png" alt='be not afraid' layout="fullWidth" />
            </div>
            <nav>
                <NavCon>
                    <Link to='/'>home</Link>
                    <Link to='/animals'>animals</Link>
                    <Link to='/synth'>synth</Link>
                </NavCon>
            </nav>
            <main>
                <PageTitle>
                    {pageTitle}
                </PageTitle>
                {children}
            </main>
        </div>
    )
}

const NavCon = styled.div`
display: flex;
justify-content: space-around;
margin-top: 1em;
`
const PageTitle = styled.h1`
width: 60vw;
margin: 1em auto;
text-align: center;
`
export default Layout
