import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { Content, PictureFrame } from '../styles/common'

const Animals = () => {

    return (
        <Layout pageTitle='зоопарк дома'>
            <Content>
                <PictureFrame>
                    <StaticImage src="../images/Albert.jpg" alt='Albert, a Cayuga drake' layout="constrained" />
                </PictureFrame>
                <p>We have our own little family of animals. At the start of the panedemic, we adopted a handful of chickens. The next year, we hatched a clutch of duck eggs. Above is Albert, he was the first of the eggs to hatch.</p>
                <PictureFrame><StaticImage src="../images/albertHatch.jpg" alt='Albert just after hatching' /></PictureFrame>
                <p>Here is albert just minutes after he broke free of his shell. Shortly after this was taken he would start helping the others by tapping on the outside of their shells.</p>
                <PictureFrame><StaticImage src='../images/chickenPile.jpg' alt='Four chickens enjoying themselves' /></PictureFrame>
                <p>The chickens have never seemed too interested in the ducks, but they do not get to spend time with each other, as drakes are capable of injuring hens.</p>
                <PictureFrame><StaticImage src='../images/TableCats.jpg' alt='Cats on a table' /></PictureFrame>
                <p>In addition to the fowl, we also have a couple of cats. Rosa is the big grey cat, and Janine is the little tortoise shell.</p>
                <PictureFrame><StaticImage src='../images/playfulJanine.jpg' alt='Janine reaching for her toy' /></PictureFrame>
            </Content>
        </Layout>
    )
}

export default Animals
